<app-navbar></app-navbar>

<section class="parallax-bg fixed-bg view-height-100vh lg-section"
    style="background-image: url('https://waobit.blob.core.windows.net/files/landing/banner.jpg')" id="home">
    <div class="parallax-overlay"></div>
    <div class="hero-text-wrap xxl-screen transparent-bg">
        <div class="hero-text">
            <div class="row">
                <div class="col-md-8 centerize-col">
                    <div class="all-padding-50 text-center white-color">
                        <div class="company-name">WAOBIT</div>
                        <h4 class="font-100 raleway-font">LABORATORIO DE MARKETING Y TRANSFORMACIÓN DIGITAL</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="white-bg xs-pb-100" id="features">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of features;" class="col-md-4 col-sm-4 col-xs-12 mb-30 hover-effect">
                <div [ngClass]="item.featureBox">
                    <img [src]="item.iconImage" alt="{{item.title}}" class="icon-img" />
                    <h4 class="mt-0 font-600">{{item.title}}</h4>
                    <p class="font-400">{{item.desc}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-6 col-sm-12">
                <div class="responsive-screen">
                    <img src="https://waobit.blob.core.windows.net/files/landing/features.jpg" class="img-responsive border-radius-25"
                        alt="features" />
                </div>
            </div>
            <div class="col-md-6 col-sm-12 text-left d-flex align-items-center">
                <div class="d-block pl-30 sm-pl-0">
                    <div class="section-title mt-50">
                        <h2 class="raleway-font default-color mt-0 line-head">En Waobit, nos apasionamos por la
                            tecnología y la innovación.</h2>
                        <h1 class="raleway-font mt-30 font-50px font-300">¿Quiénes somos?</h1>
                    </div>
                    <p>Somos un equipo dedicado a ayudar a empresas a destacarse en el entorno digital. Con servicios
                        integrales en marketing digital, desarrollo tecnológico y mensajería masiva, ofrecemos
                        soluciones que impulsan el crecimiento y la transformación de nuestros clientes.</p>
                    <p>Nuestro objetivo es convertirnos en aliados estratégicos de nuestros clientes, proporcionándoles
                        soluciones integrales de marketing digital, desarrollo tecnológico y comunicación masiva que
                        impulsen su crecimiento.</p>
                    <p class="text-center"><a routerLink="#" class="btn btn-md btn-square btn-dark-outline mt-30">Conoce
                            más de Waobit</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg pt-80 pb-80"
    style="background-image: url('https://waobit.blob.core.windows.net/files/landing/banner.jpg')">
    <div class="overlay-bg"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title mb-50 white-color text-center">
                    <h1 class="raleway-font remove-margin font-50px font-300">Procesos</h1>
                </div>
            </div>
        </div>
        <div class="row our-process-style-02">
            <div *ngFor="let step of steps" class="col-md-3 col-sm-6 col-xs-12 line xs-mb-30 sm-mb-30">
                <div class="icon-wrap white-bg">
                    <div class="icon">
                        <img [src]="step.iconImage" alt="{{step.title}}" class="icon-img" />
                    </div>
                </div>
                <div class="text-center white-color">
                    <h4 class="font-600">{{step.title}}</h4>
                    <p class="mb-0 mx-2">{{step.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gray-bg" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Somos Waobit</h2>
                    <h1 class="raleway-font">Transformación Digital</h1>
                </div>
                <p>La transformación digital es el proceso de integrar herramientas tecnológicas en todos los aspectos
                    de una empresa o institución para mejorar la eficiencia, la competitividad y la experiencia del
                    cliente. Waobit, piensa en tus necesidades como empresa para crear desarrollos digitales que sean
                    ese cohete de expansión y de éxito.</p>
                <p>Nuestro equipo de TI se encarga del proceso de transformación brindando una asesoría completa para
                    tener resultados positivos.</p>
            </div>
        </div>
        <div class="row mt-50">
            <div class="col-md-12 col-xs-12">
                <img class="img-responsive" src="https://waobit.blob.core.windows.net/files/landing/digital_transformation.jpg" alt="transformacion digital">
            </div>
        </div>
    </div>
</section>

<section class="grey-bg" id="portfolio">
    <div class="container">
        <div class="row">
            <div class="col-md-8 centerize-col text-center">
                <div class="section-title">
                    <h2 class="raleway-font default-color">Soluciones integrales</h2>
                    <h1 class="raleway-font">Nuestro Portafolio de Servicios</h1>
                </div>
                <p>Ofrecemos servicios personalizados, adaptados a las necesidades específicas de cada cliente,
                    brindando asesoría integral para asegurar que cada decisión impulse resultados positivos y
                    sostenibles en su empresa.</p>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row mt-50">
            <div *ngFor="let portfolio of portfolios" class="col-md-3 col-sm-6 portfolio with-spacing">
                <div class="portfolio-item border-white-15">
                    <a href="javascript:void(0)">
                        <img src="{{portfolio.img}}" alt="">
                        <div class="portfolio-info gradient-bg">
                            <div class="centrize">
                                <div class="v-center white-color">
                                    <h3>{{portfolio.title}}</h3>
                                    <p>{{portfolio.subTitle}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="parallax-bg fixed-bg" style="background-image: url('https://waobit.blob.core.windows.net/files/landing/business_man.jpg')">
    <div class="gradient-overlay-bg-two"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center parallax-content height-400px">
                <div class="center-layout">
                    <div class="v-align-middle">
                        <h1 class="white-color">Siente cómo tu negocio evoluciona al
                            ritmo de un mundo digital.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gradient-bg-two xs-pb-60" id="blog">
    <div class="col-md-6 col-sm-6 bg-flex bg-flex-right">
        <div class="bg-flex-holder bg-flex-cover" style="background-image: url(https://waobit.blob.core.windows.net/files/landing/blog.jpg);">
        </div>
    </div>
    <div class="container-fluid">
        <div class="col-md-6 col-sm-6">
            <div class="col-inner spacer white-color text-center">
                <h2 class="raleway-font mt-0 font-20px xs-font-17px">Lee nuestro blog</h2>
                <h1 class="raleway-font mt-0 font-50px font-300 xs-font-27px">¿Qué es la transformación Digital?</h1>
                <p>La transformación digital es un proceso que cambia por completo la forma en que una organización
                    ofrece valor a sus clientes…</p>
                <!--p><a routerLink="/blog-details" class="btn btn-md btn-square btn-light-outline mt-30">Ver detalles del
                        blog</a></p-->
            </div>
        </div>
    </div>
</section>
