<footer class="footer">
  <div class="container">
    <div class="row mt-70 align-items-center">
      <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50">
        <div class="container">
          <div class="row">
            <div class="col-md-8 centerize-col text-center">
              <div class="section-title">
                <h1 class="raleway-font default-color">Contáctanos</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Ubicación</h2>
              <p class="mt-30">Guayaquil - Ecuador</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Contácto</h2>
              <p class="mb-0 mt-30">+593 XX XXX XXXX</p>
            </div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Correo</h2>
              <p class="mb-0 mt-30">info&#64;waobit.com</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="section-title">
              <h2 class="raleway-font default-color">Soporte</h2>
              <p class="mb-0 mt-30">support&#64;waobit.com</p>
            </div>
          </div>
        </div>
        <div class="row mt-20">
          <div class="col-md-12 social-icons-style-01">
            <ul class="md-icon left-icon">
              <li *ngFor="let social of socialMedia">
                <a [ngClass]="social.iconClass" href="javascript:void(0)"><i [ngClass]="social.icon"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 xs-mb-50 d-flex align-items-center justify-content-center">
        <img 
          src="https://waobit.blob.core.windows.net/files/logo_waobit.png" 
          alt="Waobit Logo" 
          class="img-responsive img-fluid custom-image" 
        />
      </div>
    </div>
  </div>
  
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="copy-right text-left">
            © 2024 Waobit. Todos los derechos reservados
            <span> | </span>
            <a href="https://waobit.blob.core.windows.net/files/data_protection_policies.pdf"
              target="_blank" rel="noopener noreferrer">
              Protección de datos personales
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>