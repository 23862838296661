import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  socialMedia = [
    {
      iconClass: "fb",
      icon: "icofont icofont-social-facebook"
    },
    {
      iconClass: "in",
      icon: "icofont icofont-social-linkedin"
    },
    {
      iconClass: "ins",
      icon: "icofont icofont-social-instagram"
    }
  ]
  
}
