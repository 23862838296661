import { Component, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrl: './home-two.component.css'
})
export class HomeTwoComponent {

  // Features
  features = [
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient center-feature white-color border-radius-25",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/characteristics/innovation.png",
      title: "Innovación",
      desc: "Buscamos ir más allá de lo convencional, ofreciendo soluciones de marketing y transformación digital que marquen la diferencia. Con estrategias innovadoras y personalizadas, ayudando a nuestros clientes a lograr resultados tangibles.",
    },
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient-six center-feature white-color border-radius-25",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/characteristics/sustainable.png",
      title: "Futuro Sostenible",
      desc: "Creamos desarrollos e integraciones digitales que conectan de forma eficiente a la empresa con sus clientes, optimizando tiempos, eliminando tareas manuales innecesarias y gestionando la transición hacia el futuro.",
    },
    {
      featureBox: "feature-box text-center xs-mb-30 feature-box-gradient center-feature white-color border-radius-25",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/characteristics/data_protection.png",
      title: "Protección de Datos",
      desc: "Contamos con una sólida, transparente y actualizada Política de Privacidad y Tratamiento de Datos Personales que asegura el manejo ético y adecuado de la información, garantizando su confidencialidad, integridad y protección.",
    }
  ]

  // Our Process
  steps = [
    {
      icon: "icon-tools default-color font-30px",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/processes/analysis.png",
      title: "Análisis",
      desc: "Evaluamos la situación actual del cliente, sus objetivos de negocio y su presencia en el mundo digital."
    },
    {
      icon: "icon-globe default-color font-30px",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/processes/planning.png",
      title: "Planificación",
      desc: "Definimos una estrategia integral que abarca el marketing y la transformación digital."
    },
    {
      icon: "icon-mobile default-color font-30px",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/processes/development.png",
      title: "Desarrollo",
      desc: "Ejecutamos la estrategia mediante campañas de marketing digital e implementamos soluciones tecnológicas para optimizar sus procesos."
    },
    {
      icon: "icon-browser default-color font-30px",
      iconImage: "https://waobit.blob.core.windows.net/files/icons/processes/following.png",
      title: "Seguimiento",
      desc: "Monitoreamos el rendimiento del proyecto y generamos informes detallados para asegurar resultados positivos."
    },
  ]

  // Services
  services = [
    {
      icon: "icon-tools font-30px white-color",
      title: "Branding",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
    {
      icon: "icon-linegraph font-30px white-color",
      title: "Marketing",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
    {
      icon: "icon-globe font-30px white-color",
      title: "Development",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
    {
      icon: "icon-tools font-30px white-color",
      title: "Web Design",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
    {
      icon: "icon-beaker font-30px white-color",
      title: "Social Media",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
    {
      icon: "icon-layers font-30px white-color",
      title: "Research",
      desc: "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt.",
    },
  ]

  // Portfolio
  portfolios = [
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/1.jpg",
      title: "Transformación Digital",
      subTitle: "Consultoría y Desarrollos."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/2.jpg",
      title: "Marketing Digital",
      subTitle: "Planificación y estrategias."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/3.jpg",
      title: "Diseño Web",
      subTitle: "Estructura visual de tu marca."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/4.jpg",
      title: "Chat Bot",
      subTitle: "Automatización de Tareas SAC."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/5.jpg",
      title: "SMS",
      subTitle: "Mensajería Masiva / Marketing."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/6.jpg",
      title: "WhatsApp Business",
      subTitle: "Mensajería Masiva / Marketing."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/7.jpg",
      title: "Mailing",
      subTitle: "Mensajería Masiva / Marketing."
    },
    {
      img: "https://waobit.blob.core.windows.net/files/portfolio/8.jpg",
      title: "RRSS",
      subTitle: "Aumenta la visibilidad de tu marca."
    }
  ]

  // Pricing
  pricing = [
    {
      title: "Plan Pymes",
      subTitle: "Una opción asequible para la contratación de comunicación efectiva en pequeñas empresas.",
      currency: "$",
      amount: 9.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'SMS',
        'Whatsapp',
        'Mailing',
        'Creación de contenido (1 arte)',
        'Segmentación de Big Data',
        'Asesoría de Marketing para envíos',
        'Acceso a conección API'
      ]
    },

    {
      title: "Plan Emprendedor ",
      subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "$",
      amount: 16.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'SMS',
        'Whatsapp',
        'Mailing',
        'Creación de contenido (1 arte)',
        'Segmentación de Big Data',
        'Asesoría de Marketing para envíos',
        'Acceso a conección API'
      ]
    },

    {
      title: "Plan Empresas",
      subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "$",
      amount: 24.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'SMS',
        'Whatsapp',
        'Mailing',
        'Creación de contenido (1 arte)',
        'Segmentación de Big Data',
        'Asesoría de Marketing para envíos',
        'Acceso a conección API'
      ]
    }
  ];

  // Owl Testimonial
  customOptionsThree: OwlOptions = {
    loop: true,
    dots: true,
    margin: 30,
    nav: true,
    autoplay: true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 3,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 3,
      }
    }
  }

  slideStoreThree = [
    {
      title: "Anne McAdams",
      subTitle: "CEO / Founder",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sodales nec nulla ac aliquet. Duis vel nunc eget.",
      image: "assets/images/team/avatar-1.jpg",
    },

    {
      title: "Jared Kane",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-2.jpg",
    },

    {
      title: "Nani Wale",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-3.jpg",
    },

    {
      title: "John Doe",
      subTitle: "CEO / Founder",
      description: "Quisque hendrerit turpis sit amet tortor condimentum, nec commodo lorem condimentum. Sed id diam efficitur, mattis tellus ac, malesuada purus. In ultrices nisl sed eleifend consequat.",
      image: "assets/images/team/avatar-4.jpg",
    }

  ]

  // Owl Brand Logos
  customOptionsFour: OwlOptions = {
    loop: true,
    dots: false,
    margin: 0,
    nav: false,
    autoplay: true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 6,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 6,
      }
    }
  }

  slideStoreFour = [
    {
      image: "assets/images/clients/1.png",
    },

    {
      image: "assets/images/clients/2.png",
    },

    {
      image: "assets/images/clients/3.png",
    },

    {
      image: "assets/images/clients/4.png",
    },

    {
      image: "assets/images/clients/5.png",
    },

    {
      image: "assets/images/clients/6.png",
    }

  ]



  // Contact Form
  formData = {
    user_name: '',
    user_email: '',
    user_phone: '',
    user_company: '',
    message: ''
  };

  // EmailJS service configuration
  private serviceId = 'contact-form';  // Replace with your EmailJS Service ID
  private templateId = 'contact-form-becki';  // Replace with your EmailJS Template ID
  private publicKey = 'gObMl0a3V0Jib9k5c';  // Replace with your EmailJS Public Key

  // Method to send email on form submission
  public sendEmail(event: Event): void {
    event.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm(this.serviceId, this.templateId, event.target as HTMLFormElement, this.publicKey)
      .then(
        (response: EmailJSResponseStatus) => {
          alert('Message sent successfully. Thank you, we will get back to you soon!');
          this.resetForm();
        },
        (error: EmailJSResponseStatus) => {
          console.error('Failed to send message. Error:', error.text);
          alert('Failed to send the message. Please try again later.');
        }
      );
  }

  // Method to reset the form after submission
  private resetForm(): void {
    this.formData = {
      user_name: '',
      user_email: '',
      user_phone: '',
    user_company: '',
      message: ''
    };
  }

  // Skills
  skills = [
    { name: 'HTML', level: 90 },
    { name: 'CSS', level: 80 },
    { name: 'JavaScript', level: 85 },
    { name: 'Angular', level: 75 },
    { name: 'TypeScript', level: 70 }
  ];

  isScrolled = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    const skillsSection = document.querySelector('.skill');

    // Add a null check for skillsSection
    if (skillsSection) {
      const position = skillsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Check if the section is in view
      if (position < windowHeight) {
        this.isScrolled = true;
      }
    }
  }

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
