<nav class="navbar navbar-expand-lg nav-crev" [class.nav-scroll]="isSticky">
    <div class="container">
        <a class="navbar-brand icon-img-100" [routerLink]="'/'">
            <img [src]="logo" alt="Logo" class="logo logo-img">
        </a>
        <button class="navbar-toggler ms-auto" type="button" (click)="isCollapsed = !isCollapsed"
            data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="icon-bar"><i class="icofont icofont-navigation-menu"></i></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{'collapse': isCollapsed, 'navbar-collapse': true}"
            id="navbarNavAltMarkup">
            <ul class="navbar-nav navbar-center xs-mt-20">
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('home')">Principal</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('features')">Características</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('about')">Quienes somos</a>
                </li>
                <li class="nav-item dropdown">
                    <a (click)="scrollToSection('portfolio')" class="nav-link dropdown-toggle" id="portfolioDropdown"
                        role="button" aria-expanded="false">
                        Servicios
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="portfolioDropdown">
                        <li><a class="dropdown-item" routerLink="/portfolio-web">Web Design</a></li>
                        <li><a class="dropdown-item" routerLink="/portfolio-graphic">Graphic Design</a></li>
                        <li><a class="dropdown-item" routerLink="/portfolio-photo">Photography</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="scrollToSection('blog')">Blog</a>
                </li>
            </ul>
        </div>
    </div>
</nav>